






import StaffFranchiseListPage from './StaffFranchiseManager.vue';
import Vue from 'vue';
export default Vue.extend({
  components: {
    StaffFranchiseListPage,
  },
});
