






import Vue from 'vue';
import StaffFranchiseList from '@/components/stafffranchisemanager/StaffFranchiseList.vue';
export default Vue.extend({
  name: 'StaffFranchiseListPage',
  components: {
    StaffFranchiseList,
  },
});
